import React from 'react';
import { makeStyles } from '@material-ui/core';
import MyCustomLogoIcon from './logo/ws_logo_icon.png';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 40,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();
  return <img className={classes.svg} src={MyCustomLogoIcon} />;
};

export default LogoIcon;
