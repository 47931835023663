import {
  HomePageToolkit,
  HomePageStarredEntities,
  TemplateBackstageLogoIcon,
  ComponentAccordion
} from '@backstage/plugin-home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import KeyIcon from '@material-ui/icons/Security';
import BarChart from '@material-ui/icons/BarChart';
import { Content, Page, InfoCard } from '@backstage/core-components';
import {
  HomePageSearchBar
} from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
  infoCard: {
    textAlign: 'justify',
    margin: '30px',
    fontSize: '16px',
    height: 'auto'
  },
  link: {
    color: '#0000EE',
  },
}));


export const HomePage = () => {
  const classes = useStyles();
  const ExpandedComponentAccordion = (props: any) => (
    <ComponentAccordion expanded {...props} />
  );

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <InfoCard title="Welcome to Orion Platform!">
                  <div className={classes.infoCard}>
                    <p>
                      This is where you will find all the necessary resources and information to interact with
                      Engineering Platform. Before everything you should read our
                      <a className={classes.link} href="/docs/default/resource/platform-docs" > documentation </a>
                      and get familiar the architecture and the glossary terms.
                    </p>
                    <p>
                      Here you will find the <a className={classes.link} href="/catalog" > catalog </a>
                      of Web Summit services and resources deployed on the platform,
                      their current status, relevant links, links to our external platform service providers,
                      <a className={classes.link} href="/create" > templates </a> to create or edit services/resources,
                      etc...
                    </p>
                    <p>
                      You can use the search bar to find documentation or resources that you are looking for.
                      If you could not find the information needed, have a suggestion or find any bug
                      please reach out to the SRE team on the slack channel
                      <a className={classes.link} href="#" > #engineering-infrastructure </a>
                    </p>
                    <h4>
                      Thank you!
                    </h4>
                  </div>
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard title="Useful Links" noPadding>
                  <HomePageToolkit
                    title="Generic"
                    Renderer={ExpandedComponentAccordion}
                    tools={[
                      {
                        url: '/docs/default/resource/platform-docs',
                        label: 'Docs',
                        icon: <LibraryBooks />,
                      },
                      {
                        url: '/create/tasks',
                        label: 'Tasks',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                    ]}
                  />
                  <HomePageToolkit
                    title="Vault"
                    Renderer={ComponentAccordion}
                    tools={[
                      {
                        url: 'https://secrets.orion-grs.com/ui/vault/auth?with=oidc%2F',
                        label: 'Vault',
                        icon: <KeyIcon />,
                      },
                    ]}
                  />
                  <HomePageToolkit
                    title="New Relic"
                    Renderer={ComponentAccordion}
                    tools={[
                      {
                        url: 'https://onenr.io/0ZQWa3WvaRW',
                        label: 'STG',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                      {
                        url: 'https://onenr.io/08woa2NaWjx',
                        label: 'QA',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                      {
                        url: 'https://onenr.io/0MRNEa29lwn',
                        label: 'PROD',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                    ]}
                  />
                  <HomePageToolkit
                    title="ArgoCD"
                    Renderer={ComponentAccordion}
                    tools={[
                      {
                        url: 'https://stg-argocd.orion-grs.com/',
                        label: 'STG',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                      {
                        url: 'https://qa-argocd.orion-grs.com/',
                        label: 'QA',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                      {
                        url: 'https://prod-argocd.orion-grs.com/',
                        label: 'PROD',
                        icon: <TemplateBackstageLogoIcon />,
                      },
                      {
                        url: 'https://argocd.mgmt-plus-us-east-1-1.mgmt-plus.stamp.summitengine.com/',
                        label: 'Infra +',
                        icon: <TemplateBackstageLogoIcon />,
                      }
                    ]}
                  />
                  <HomePageToolkit
                    title="Monitoring"
                    Renderer={ComponentAccordion}
                    tools={[
                      {
                        url: 'https://monitoring.orion-grs.com/app/dashboards#/view/4d647930-5640-11ee-88c2-a56ce6dd7adc?_g=(filters:!())',
                        label: 'Kibana',
                        icon: <BarChart />,
                      },
                      {
                        url: 'https://monitoring.orion-grs.com/app/dashboards#/view/25a34ad0-459b-11ee-9770-9965b86d7c19?_g=(filters:!(),refreshInterval:(pause:!t,value:60000),time:(from:now-15m,to:now))',
                        label: 'Global View',
                        icon: <BarChart />,
                      }
                    ]}
                  />
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
